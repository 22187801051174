<template>
  <nav class="bg-customNavbar flex justify-between items-center px-4 py-0 sm:px-4 sm:py-2" @click.self="emitToggleNavbar">
    <!-- Placeholder for Menu for Guest Facilitator -->
    <div v-if="currentRole === 'Guest Facilitator'" @click="handleHamburgerIconClick" class="flex sm:hidden items-center mr-2 hs-tooltip inline-block">
      <svg class="hs-tooltip-toggle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>

    <!-- Hambuger Menu Icons -->
    <div v-if="currentRole === 'Educator' || currentRole === 'System Manager'" class="flex sm:hidden items-center mr-2 hs-tooltip inline-block" aria-haspopup="dialog" aria-expanded="true" aria-controls="hs-offcanvas-example-admin" aria-label="Toggle navigation" data-hs-overlay="#hs-offcanvas-example-admin">
      <svg class="hs-tooltip-toggle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>

    <div v-if="currentRole === 'Parent'" class="flex sm:hidden items-center mr-2 hs-tooltip inline-block" aria-haspopup="dialog" aria-expanded="true" aria-controls="hs-offcanvas-example-parent" aria-label="Toggle navigation" data-hs-overlay="#hs-offcanvas-example-parent">
      <svg class="hs-tooltip-toggle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>

    <!-- App Logo -->
    <div class="hidden md:flex items-center ml-5 mt-2.5 md:mt-0">
      <router-link to="/front-portal">
        <img src="@/assets/app-logos/zippielogo.png" alt="Currie Navbar Logo" class="w-[75.25px] h-[32px] md:w-[99px] md:h-[42px]" />
      </router-link>
    </div>

    <!-- Mobile Logo -->
    <div class="md:hidden flex items-center ml-3">
      <router-link to="/front-portal">
        <img src="@/assets/app-logos/zippie-new-z-logo.png" alt="app logo" class="w-[24px] h-[24px]" />
      </router-link>
    </div>

    <!-- Right Side Icons -->
    <div class="ml-auto flex justify-end items-center h-[50px] w-[150px] sm:h-[60px] sm:w-[200px] print:hidden">
      <div class="flex items-stretch md:border-r border-black pr-2 py-1 md:gap-3">
        
        <!-- Message Icon -->
        <div v-if="userRoleView && userRoleView =='Parent'" class="">          
          <router-link to="/front-portal/parent-messages" class=""><MessagesIcon /></router-link>
        </div>  

        <!-- Notifications -->  
          <!-- <div>
            <button @click="addToTestArray({ id: Date.now(), name: 'Test Notification' })">
              Add Test Notification
            </button>
          </div> -->
        
        <Notifications />
      </div>

      <!-- Profile Dropdown -->
      <div class="md:pl-4">
        <ProfileDropdown v-if="currentRole" @roleChanged="handleRoleChange" @show-install-zippie-modal="onShowInstallZippieModal" :current-role="currentRole" class="z-50" />
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, watch, computed, onMounted, watchEffect } from 'vue';
import SearchIcon from './icons/SearchIcon.vue';
import MessagesIcon from './icons/MessagesIcon.vue';
import ProfileDropdown from './ProfileDropdown.vue';
import NotificationIcon from './icons/NotificationIcon.vue';
import Notifications from './notifications/Notifications.vue';





import { useNotifications } from '../lib/state/notifications';
const { readNotifications, rtNotificationQueue, newNotificationsPresent,
        notificationList, unreadCount,testArray, addToTestArray, setFetchedNotifications,
        addToQueue, makeInactive, addToReadList, 
        removeFromReadList} = useNotifications();


// Watch testArray
watch(testArray, (newVal, oldVal) => {
  console.log('tt:Watcher triggered for testArray:', newVal);
}, { immediate: true, deep: true });

// Watch notificationList properly - it's a computed ref
watch(() => notificationList.value, (newVal, oldVal) => {
  console.log("tt:Notification List Updated in navbar:", newVal);
}, { immediate: true, deep: true });

// Watch rtNotificationQueue
watch(() => rtNotificationQueue.value, (newVal, oldVal) => {
  console.log('tt:Queue Updated in navbar:', newVal);
}, { immediate: true, deep: true });

// Add a watchEffect for debugging
watchEffect(() => {
  console.log('Navbar watchEffect - notification count:', notificationList.value.length);
  console.log('Navbar watchEffect - queue length:', rtNotificationQueue.value.length);
});




// Props
defineProps({
  userDetails: {
    type: Object,
    required: true,
  },
  currentRole: {
    type: String,
    required: true,
  },
  userRoleView: {
    type: String,
    required: true,
  },
});

// Emit
const emit = defineEmits(['show-modal', 'toggle-navbar', 'show-role-switch-confirmation-modal', 'show-install-zippie-modal']);

// Computed property
const isOnlyGuestFacilitator = computed(() => {
  const roles = userDetails?.user_roles || [];
  return roles.length === 1 && roles[0] === 'Guest Facilitator';
});

// Methods
const handleHamburgerIconClick = () => {
  emit('show-modal');
};

const emitToggleNavbar = () => {
  if (window.innerWidth < 768) {
    emit('toggle-navbar');
  }
};

const handleRoleChange = () => {
  emit('show-role-switch-confirmation-modal');
  setTimeout(() => {
    console.log('tt: Preline initialized after fetching');
  }, 100);
};

const onShowInstallZippieModal = () => {
  emit('show-install-zippie-modal');
};

// Lifecycle Hooks
onMounted(() => {
  console.log('tt: Navbar mounted');
  setTimeout(() => {
    window.HSStaticMethods?.autoInit();
    console.log('tt: Preline initialized inside Navbar after 1000ms delay');
  }, 1000);
});
</script>
