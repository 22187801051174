import { computed, ref, watchEffect } from "vue";

export function useNotifications() {
  // state
  const readNotifications = ref([])           // Notifications(id) that have been read

  const rtNotificationQueue = ref([])         // A queue for adding new notifications
  const newNotificationsPresent = ref(true)

  const fetchedNotifications = ref([])        // notifications fetched from the server initially

  const testArray = ref([]);


  const addToTestArray = (item) => {
    console.log("addd to test notification clicked in composable")
    testArray.value = [...testArray.value, item]; // Update with a new reference
    console.log("this is test array value",testArray.value)
  };


  // List of all the notifications
  const notificationList = computed(() => {
    try {
  
      const notifications = [...rtNotificationQueue.value, ...fetchedNotifications.value];
  
      readNotifications.value.forEach((notiName) => {
        const notiIndex = notifications.findIndex((noti) => noti.name === notiName);
        if (notiIndex === -1) return; // Fixed typo: was 'retsurn'
        notifications[notiIndex].status = 'Read';
      });
  
      console.log("Combined notifications:", notifications);
      return notifications;
    } catch (error) {
      console.error('Error in notificationList computed:', error);
      return [];
    }
  });

  const unreadCount = computed(() => {
    return notificationList.value.filter((notification) => notification.status === 'Unread').length
  })

  const setFetchedNotifications = (notificationList) => {
    fetchedNotifications.value = [...fetchedNotifications.value,...notificationList];
    
  }

  const addToQueue = (sampleNoti) => {
    // console.log("Adding new notification")
    // console.log('notificationQue', rtNotificationQueue.value)
    const newIndex = rtNotificationQueue.value.length;
    // console.log("new index", newIndex)
    rtNotificationQueue.value.push({
      ...sampleNoti,
      actor_name: `${sampleNoti.actor_name}-${newIndex}`,
      active: true
    })

    newNotificationsPresent.value = true;

    setTimeout(() => {
      rtNotificationQueue.value[newIndex].active = false;
    }, 5000)
  }
  

  const makeInactive = (queueIndex) => {
    rtNotificationQueue.value[queueIndex].active = false;
  }

  const addToReadList = (notificationId) => {
    readNotifications.value.push(notificationId)
  }

  const removeFromReadList = (notificationId) => {
    readNotifications.value = readNotifications.value.filter((noti) => noti.name !== notificationId)
  }
  // In your composable
watchEffect(() => {
  console.log('rtNotificationQueue changed:', rtNotificationQueue.value);
  console.log('fetchedNotifications changed:', fetchedNotifications.value);
  console.log('computed notificationList:', notificationList.value);
});

  return {
    readNotifications, rtNotificationQueue, newNotificationsPresent,
    notificationList, unreadCount,testArray, addToTestArray, setFetchedNotifications,
    addToQueue, makeInactive, addToReadList, 
    removeFromReadList
  }
}

